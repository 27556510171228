import {
    ColumnLayout,
    FormField,
    Input,
    Container,
    Header,
    Checkbox,
} from '@amzn/awsui-components-react-v3';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useProvider } from '@/data/ProviderContext';

import { messages } from '../ClassForm.messages';
import { LOCATION_SOURCES, handleFormValueChange } from '../ClassForm.utils';
import { showErrorMessage } from '../FieldErrors';

import '../ClassForm.css';

const AdvancedSettings = ({
    setClassData,
    learningActivityID,
    source,
    fieldsInvalid,
    handleFormSubmit,
    isGrimsbyClass,
}) => {
    const { formatMessage } = useIntl();
    const { search } = useLocation();
    const [checked, setChecked] = useState(source === LOCATION_SOURCES.LADS);
    const isDirectProvider = useProvider()?.type === 'DIRECT';
    const queryParams = new URLSearchParams(search);
    const isAdvancedMode = queryParams.get('mode') === 'advanced';

    const handleKeyboardSubmit = (e) => {
        if (e.detail.key === 'Enter') handleFormSubmit(e);
    };

    const valueFormatter = (code, values) => {
        if (code === 'LearningActivityMismatch') {
            return values
                .map((val) => {
                    switch (val) {
                        case 'classCapacity':
                            return 'Number of students';
                        case 'course':
                            return 'Course';
                        case 'instructors':
                            return 'Instructors';
                        case 'virtualUrl':
                            return 'Virtual classroom URL';
                        default:
                            return val;
                    }
                })
                .join(', ');
        }
        return values;
    };

    return isDirectProvider && (isAdvancedMode || learningActivityID) ? (
        <Container
            id='advanced-settings'
            header={<Header variant='h3'>{formatMessage(messages.advancedSettingsHeader)}</Header>}
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root='true'>
                    <FormField
                        stretch
                        description={formatMessage(messages.grimsbyActivityIdDescription)}
                        label={formatMessage(messages.grimsbyActivityIdLabel)}
                        errorText={showErrorMessage(formatMessage, {
                            fieldsInvalid,
                            valueFormatter,
                            property: 'learningActivityID',
                        })}
                    >
                        <Input
                            value={learningActivityID}
                            data-testid='learning-activity-id'
                            invalid={!!fieldsInvalid.learningActivityID}
                            onChange={(e) => {
                                handleFormValueChange({
                                    value: e.detail.value,
                                    setData: setClassData,
                                    keyPath: 'learningActivityID',
                                });
                            }}
                            onKeyDown={handleKeyboardSubmit}
                            disabled={isGrimsbyClass}
                        ></Input>
                    </FormField>
                    <FormField>
                        <Checkbox
                            checked={checked}
                            ariaLabel={formatMessage(messages.locationSourceLabel)}
                            onChange={({ detail }) => {
                                setChecked(detail.checked);
                                handleFormValueChange({
                                    value: detail.checked
                                        ? LOCATION_SOURCES.LADS
                                        : LOCATION_SOURCES.DEFAULT,
                                    setData: setClassData,
                                    keyPath: 'source',
                                });
                            }}
                            id='ClassForm__source'
                            disabled={isGrimsbyClass}
                        >
                            {formatMessage(messages.locationSourceLabel)}
                        </Checkbox>
                    </FormField>
                </div>
            </ColumnLayout>
        </Container>
    ) : null;
};

export default AdvancedSettings;
